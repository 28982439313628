import React, { useEffect } from "react"

const SilkRoute = () => {
  useEffect(() => {
    if (typeof window !== undefined) {
      window.location.href = "https://dare2compete.com/o/hfbkW4x?refId=C9AwjtE"
    }
  }, [])
  return <></>
}

export default SilkRoute
